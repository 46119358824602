<section class="body">
  <header class="header">
    <div class="logo-container">
      <a routerLink="/" class="logo" style="margin-top:5px;">
        <img src="assets/images/pitten_plain_red.svg" height="45" alt="PITTEN" />
      </a>
    </div>
  </header>
  <div class="inner-wrapper">
    <app-main-menu [showNotification]="false"></app-main-menu>
    <section role="main" class="content-body">
      @if (newUserRequired) {
        <div class="main-content">
          <h2>Ny bruger</h2>
          <br>
            <p>
              Vi har ikke fundet en bruger tilknyttet til dette LinkedIn bruger.
              for at oprette en ny bruger indtast venligst din nye adgangskode og check dine oplysninger passer. Bemærk din e-mail skal passe med du har i LinkedIn hvis du fortsat gerne ville bruge LinkedIn til login
            </p>
            <form [formGroup]="profileForm">
              <div class="form-group">
                <label for="firstname" class="form-label">Fornavn: </label>
                <input type="text" placeholder="Fornavn" class="form-control" id="firstname" formControlName="firstname">
              </div>
              <div class="form-group">
                <label for="lastname" class="form-label">Efternavn: </label>
                <input type="text" placeholder="Efternavn" class="form-control" id="lastname" formControlName="lastname">
              </div>
              <div style="margin-bottom: 50px;"></div>
              <div class="form-group">
                <label for="email" class="form-label">E-mail: </label>
                <input type="text" readonly placeholder="example@example.com" class="form-control" id="email" formControlName="email">
              </div>
              <div class="form-group">
                <label for="password" class="form-label">Password: </label>
                <input type="password" placeholder="*******" class="form-control" id="password" formControlName="password">
              </div>
              <div style="margin-bottom: 50px;"></div>
              <div class="form-group text-center">
                <button class="btn btn-danger" (click)="cancel()" type="button">Annuller</button>
                <input class="btn submit" type="submit" value="Opret" (click)="submit()" >
              </div>
            </form>
          </div>
        }
      </section>
    </div>
  </section>
