<header>
  <div class="customer-language-picker-area">
    <div class="picker-label">
      <label class="switch">
        <input
          type="checkbox"
          (change)="useLang($event.target.checked ? 'en' : 'da')"
          [checked]="currentLang === 'en'"
          />
        <span class="slider round">
          <span class="lang lang-da">Dansk</span>
          <span class="slider-color"></span>
          <span class="lang lang-en">English</span>
        </span>
      </label>
    </div>
  </div>
</header>

<div class="change-password">
  <section class="change-password__container">
    <div class="change-password__header">
      <a class="change-password__logo" routerLink="/">
        <img
          src="assets/images/Pitten_plain_new.svg"
          alt="PITTEN"
          height="30"
          />
      </a>
    </div>
    @if (!showConfirmation) {
      <div class="change-password__text">
        <p class="change-password__title">
          {{ "menu.changePassword" | translate }}
        </p>
        <p class="change-password__subtitle">
          {{ "changePassword.changePasswordSubtitle" | translate }}
        </p>
      </div>
      <form
        [formGroup]="changePasswordForm"
        class="change-password__form"
        (ngSubmit)="onSubmit()"
        >
        <input
          type="password"
          class="change-password__input"
                    [placeholder]="
                        'changePassword.passwordPlaceholder' | translate
                    "
          formControlName="password"
          />
        <input
          type="password"
          class="change-password__input"
                    [placeholder]="
                        'changePassword.confirmPasswordPlaceholder' | translate
                    "
          formControlName="passwordConfirm"
          />
        <button type="submit" class="change-password__submit-btn new-btn new-btn-dark">
          {{ "changePassword.changePasswordButton" | translate }}
        </button>
      </form>
    } @else {
      <div class="change-password__confirmation">
        <p class="change-password__title">
          {{ "changePassword.passwordChangedTitle" | translate }}
        </p>
        <p class="change-password__subtitle">
          {{ "changePassword.passwordChangedSubtitle" | translate }}
          <a routerLink="/">{{ "changePassword.here" | translate }}</a
            >.
          </p>
        </div>
      }

    </section>
  </div>
