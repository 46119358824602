<div class="navigation-menu" [ngClass]="{ 'small-text': isAdmin }">
  @if (currentUser) {
    <div id="userbox" class="userbox">
      @if (!isAdmin) {
        <app-profile-image
          [imagePath]="currentUser.imagePath"
          [alt]="currentUser.name"
          [width]="100"
          [showAsBackground]="true"
          >
        </app-profile-image>
      }
      <div class="user-info" [ngClass]="{ 'small-text': isAdmin }">
        <span class="name"
          ><p>{{ currentUser.name }}</p></span
          >
          @if (roleText) {
            <span class="role"
              ><p>{{ roleText }}</p></span
              >
            }
            @if (currentUser.email) {
              <span class="email"
                ><p>{{ currentUser.email }}</p></span
                >
              }
            </div>
          </div>
        }
        <ul class="nav nav-main">
          @if (currentUser && currentUser.userRoleEnumDto == 2) {
            @for (link of adminLinks; track link) {
              <li
                [ngClass]="{ menuheader: link.addClass }"
                >
                @if(link.route !== "menuheader") {
                  <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                    <span>{{ link.menuItemName }}</span>
                  </a>
                  }@else {
                  <span >{{ link.menuItemName }}</span>
                }
              </li>
            }
          }

          @if (currentUser && currentUser.userRoleEnumDto == 1) {
            @for (link of profileLinks; track link) {
              <li>
                <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                  <span>{{ link.menuItemName }}</span>
                </a>
              </li>
            }
          }

          @if (currentUser && currentUser.userRoleEnumDto == 3) {
            @for (link of customerProfileLinks; track link) {
              <li>
                <a routerLink="{{ link.route }}" (click)="closeMenus($event)">
                  <img [src]="link.icon" alt="Icon" />
                  <span class="menu-item">{{ link.menuItemName }}</span>
                </a>
              </li>
            }
          }

          <li class="data" [ngClass]="{ 'small-text': isAdmin }">
            <a
              href="/assets/data-protection-and-privacy-policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              ><img src="../../../../assets/images/AlignBottom.svg" /><span
              class="menu-item menu-item-long"
              >{{ "labels.dataAndPrivacy" | translate }}</span
              ></a
              >
            </li>

            <li class="change-password" [ngClass]="{ 'small-text': isAdmin }">
              <a (click)="requestChangePassword()" (click)="closeMenus($event)">
                <img src="../../../../assets/images/padlock.svg" />
                <span class="menu-item">{{
                  "menu.changePassword" | translate
                }}</span>
              </a>
            </li>

            <li class="logout" [ngClass]="{ 'small-text': isAdmin }">
              <a (click)="logout()"
                ><img src="../../../../assets/images/SignOut.svg" /><span
                class="menu-item"
                >{{ "menu.logOut" | translate }}</span
                ></a
                >
              </li>
            </ul>
          </div>
