<section class="body">
  <header class="header d-flex justify-content-between">
    <div class="logo-container">
      <a routerLink="/" class="logo" style="margin-top:5px;">
        <img src="assets/images/pitten_plain_red.svg" height="45" alt="PITTEN" />
      </a>
    </div>
    <div class="language-picker-area">
      <div class="picker-label">
        <select class="language-picker" (change)="useLang($event.target.value)" [(ngModel)]="currentLang">
          <option value="da"> Dansk </option>
          <option value="en"> English </option>
        </select>
      </div>
    </div>

    <div class="todo-wrapper">
      <div class="todo-list">
        @if (!isTodoShown) {
          <button class="btn btn-primary btn-outline-primary todo-button" (click)="toggleTodoList(true)">ToDo</button>
        }
        @if (isTodoShown) {
          <button class="btn btn-primary btn-outline-primary todo-button" (click)="toggleTodoList(false)"><i class="fa fa-times"></i></button>
        }
        @if (isTodoShown) {
          <app-todo-list-full class="todo-list-full-shown"></app-todo-list-full>
        }
      </div>
      <div [class.fixed-bg]="isTodoShown" (click)="toggleTodoList(false)"></div>
    </div>
  </header>
  <div class="inner-wrapper" >
    <app-main-menu [showNotification]="true"></app-main-menu>
    <section role="main" class="content-body">
      <router-outlet></router-outlet>
    </section>
  </div>
</section>