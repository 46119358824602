import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationApiService } from '../../../core/api/notification-api.service';
import { NotificationListItem } from '../../../core/models/notification-list-item';
import { DateFormatEnum } from 'src/app/core/helpers/date-format-enum';
import { NotificationService } from '../../../core/services/notification.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-notification-menu',
    templateUrl: './notification-menu.component.html',
    styleUrls: ['./notification-menu.component.scss'],
    standalone: true,
    imports: [DatePipe]
})
export class NotificationMenuComponent implements OnInit, OnDestroy {

    @Output() public onUnreadCountChanged = new EventEmitter<number>(); // The parent component can listen for this, to get number of unread notifications, so that it can write the number next to the notification menu-button.

    public notificationListItems: NotificationListItem[] = [];
    public expandedNotificationId: number | null = null;

    public totalUnreadNotifications = 0;
    public totalNotifications = 0;
    public page = 0;
    public readonly ITEMS_PER_PAGE = 10;

    public dateFormatEnum = DateFormatEnum; // just to pass the enum on to the html.

    constructor(
        private notificationApiService: NotificationApiService,
        private notificationService: NotificationService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.loadData();
        this.notificationService.addNotificationListener(this.onNotificationReceived); // So that the notification-list is updated, when a new notification arrives, without having to do a page-reload
    }

    ngOnDestroy() {
        this.notificationService.removeNotificationListener(this.onNotificationReceived);
    }

    private onNotificationReceived = (data) => {
        this.loadData();
    }

    private loadData() {
        this.notificationApiService.getCurrentUserNotifications(this.page, this.ITEMS_PER_PAGE).subscribe(notificationListResult => {
            this.notificationListItems = notificationListResult.notificationItems;
            this.totalUnreadNotifications = notificationListResult.totalUnreadItems;
            this.totalNotifications = notificationListResult.totalNumberOfItems;
            this.onUnreadCountChanged.emit(this.totalUnreadNotifications); // notify parent component
        });
    }

    public onClickNotificationItem(event: MouseEvent, notificationItem: NotificationListItem) {
        event.stopImmediatePropagation();

        this.expandedNotificationId = notificationItem.id == this.expandedNotificationId ? null : notificationItem.id;

        // Mark the notification as read, if it isn't already.
        if (!notificationItem.isRead) {
            notificationItem.isRead = true; // set isRead immediately, so it's nicer in the UI.
            this.notificationApiService.setNotificationRead(notificationItem.id).subscribe(_ => {
                this.totalUnreadNotifications--;
                this.onUnreadCountChanged.emit(this.totalUnreadNotifications); // notify parent component
            }, error => {
                notificationItem.isRead = false; // An error happened - set isRead back again...
            });
        }
    }

    public getTotalNumberOfPages() {
        return Math.ceil(this.totalNotifications / this.ITEMS_PER_PAGE);
    }

    public onGoToClick(value: string) {
        //Need to do this way to ensure reload is done on same component
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigateByUrl(value));
    }

    public onPrevPageClick() {
        if (this.page <= 0) return;
        this.page--;
        this.loadData();
    }

    public onNextPageClick() {
        if (this.page >= this.getTotalNumberOfPages() - 1) return;
        this.page++;
        this.loadData();
    }
}
